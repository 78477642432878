/*
Color scheme:
https://designshack.net/articles/trends/best-website-color-schemes/ - Scheme #32:
#5CE0D8, #01345B, #FFCF43
fonts: #FFF, #99b3c1
calculators:
  - form background: #F4F4F6
*/


.App {
  font-family: Lato, Montserrat, Roboto;
  font-size: 0.9em;
}

.App-logo {
  height: 40vmin;
}

.navbar-brand {
  display: flex;
  align-items: center;
}
.navbar-brand>img {
  padding: 7px 14px;
}

.warning-msg {
  text-align: center;
  background-color: purple;
  color: white;
  font-size: 1em;
  font-weight: bold;
}

.App-header {
  background-color: #01345B;
}

.App-content {
  margin-top: 3vw;
  margin-bottom: 10vw;
}

.App-link {
  color: #09d3ac;
}

/* Navigation */
.navbar-light .navbar-nav .nav-link {
  color: #FFFFFF;
}

.navbar-light .navbar-brand {
  color: #FFFFFF;
}

/* Main Page */

.cards-row {
  margin: 2vw auto;
}

/* Grid */

.themed-grid-col {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #FFF;
  //background-color: rgba(86, 61, 124, .15);
  //border: 1px solid rgba(86, 61, 124, .2);
}

.themed-grid-col-sidebar {
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  //background-color: #FFF;
  background-color: rgba(86, 61, 124, .15);
  border: 1px solid rgba(86, 61, 124, .2);
}

/* Calculator - Grid */

.calc-grid-form {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #F4F4F6;
}

.calc-grid-charts {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #FFF;
}

/* Common to all calculators */


/* Footer */
.footer {
  flex-shrink: 0;
  text-align: center;
  background-color: #01345B;
  color: #FFFFFF;
}



/*** Custom styles that override Bootstrap ***/

/* remove rounding from cards, buttons and inputs */
.card, .btn, .form-control { border-radius: 0; }

/* From: https://stackoverflow.com/questions/24210132/remove-border-radius-from-select-tag-in-bootstrap-3 */
select:not([multiple]) {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: right 50%;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
  padding: .5em 1.5em .5em .5em;
}

