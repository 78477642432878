.enterValuesMessage {
    margin-top: 2em;
    text-align: center;
    font-size: 1.8em;
}

.results {
    height: 100%;
}

.results .summary-total {
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
}

.totalSavings {
    font-size: 2em;
    font-weight: bold;
}

.results .summary-breakdown {
    text-align: left;
}

.results .chart {
    margin-top: 1em;
}